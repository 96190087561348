<template>

  <div class="container user-section">
		<div class="row">
			<div class="col-12">

        <b-overlay variant="white" :show="sending" no-wrap></b-overlay>

        <h3>Vaihda salasana</h3>
        <p>Salasana on vaihdettava 180 päivän välein.</p>

        <form @submit.prevent="savePass">
          <div class="form-group text-right">
            <button class="btn btn-success" type="submit"><i class="fas fa-save"></i> Tallenna</button>
          </div>
          <div class="form-group mobile">
            <label class="form-label">Nykyinen salasana</label>
            <input type="password" class="form-control" v-model="password.old" required />
          </div>
          <div class="form-group mobile">
            <label class="form-label">Uusi salasana</label>
            <input type="password" class="form-control" v-model="password.new" required />
          </div>
          <div class="form-group mobile">
            <label class="form-label">Vahvista uusi salasana</label>
            <input type="password" class="form-control" v-model="password.confirm" required />
          </div>
        </form>

        <b-alert variant="danger" :show="error? true : false" v-html="error"></b-alert>

			</div>
		</div>
	</div>

</template>

<script>
export default {
  data: function () {
		return {
			error: false,
      sending: false,
      password: {
				old: '',
				new: '',
				confirm: ''
			}
		}
	},
	methods: {
    savePass: function() {
      var vm = this;
			if(!vm.sending) {
        vm.error = false;
				vm.sending = true;
				this.axios({
					method: 'post',
					url: '/api/profile/password',
					data: vm.password
				})
				.then(function (response) {
					if(response.data.success) {
						vm.$parent.makeToast('success', 'Salasanan vaihto', 'Onnistui!');
            vm.password = { old: '', new: '', confirm: '' };
            vm.$parent.$parent.changepass = false;
					} else {
            vm.error = response.data.message;
						vm.$parent.makeToast('warning', 'Salasanan vaihto', 'Epäonnistui, tarkista salasanat!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Salasanan vaihto', 'Epäonnistui!');
				})
			}
    }
	}
}
</script>
